import { useState, useContext } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import {
  USER,
  VENDOR,
  SOURCE,
  PRODUCT,
  CUSTOMER,
  ADDRESS,
} from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import UserForm from "../library/form/User";
import VendorFrom from "../library/form/Vendor";
import ProductForm from "../library/form/Product";
import ThumbnailForm from "../library/form/Thumbnail";
import CustomerForm from "../library/form/Customer";
import AddressForm from "../library/form/Address";
import SourceForm from "../library/form/Source";

function Update(props) {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");
  const [isManager] = useState(user.data.role === "MANAGER");

  const navigate = useNavigate();
  const { type, id } = useParams();

  return (
    <UserLayout title={`Update ${type}`}>
      <HeadSection heading={`Update ${type}`}>
        <button className="btn btn-outline" onClick={() => navigate(-1)}>
          Back
        </button>
      </HeadSection>
      <section className="pt-8 space-y-8 max-w-md">
        {type && basedOnType(type, isAdmin, isManager, id)}
      </section>
    </UserLayout>
  );
}

export default Update;

const basedOnType = (type, isAdmin, isManager, id) => {
  if (isAdmin) {
    switch (type) {
      // user
      case "user":
        return <UpdateUser id={id} />;
      // vendor
      case "vendor":
        return <UpdateVendor id={id} />;
      // source
      case "source":
        return <UpdateSource id={id} />;
      // product
      case "product":
        return <UpdateProduct id={id} />;
      // thumbnail
      case "thumbnail":
        return <UpdateThumbnail id={id} />;
      // customer
      case "customer":
        return <UpdateCustomer id={id} />;
      // address
      case "address":
        return <UpdateAddress id={id} />;
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  } else if (isManager) {
    switch (type) {
      // product
      case "product":
        return <UpdateProduct id={id} />;
      // thumbnail
      case "thumbnail":
        return <UpdateThumbnail id={id} />;
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  } else {
    switch (type) {
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  }
};

function UpdateUser(props) {
  const { id } = props;

  const { data } = useQuery(USER, {
    variables: { userId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <UserForm mode="update" info={data?.user} id={id} />;
}

function UpdateVendor(props) {
  const { id } = props;

  const { data } = useQuery(VENDOR, {
    variables: { vendorId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <VendorFrom mode="update" info={data?.vendor} id={id} />;
}

function UpdateProduct(props) {
  const { id } = props;

  const { data } = useQuery(PRODUCT, {
    variables: { productId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <ProductForm mode="update" info={data?.product} id={id} />;
}

function UpdateThumbnail(props) {
  const { id } = props;

  const { data } = useQuery(PRODUCT, {
    variables: { productId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <ThumbnailForm mode="update" info={data?.product} id={id} />;
}

function UpdateCustomer(props) {
  const { id } = props;

  const { data } = useQuery(CUSTOMER, {
    variables: { customerId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <CustomerForm mode="update" info={data?.customer} id={id} />;
}

function UpdateAddress(props) {
  const { id } = props;

  const { data } = useQuery(ADDRESS, {
    variables: { addressId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <AddressForm mode="update" info={data?.address} id={id} />;
}

function UpdateSource(props) {
  const { id } = props;

  const { data } = useQuery(SOURCE, {
    variables: { sourceId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <SourceForm mode="update" info={data?.source} id={id} />;
}
