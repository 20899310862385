import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CHANGE_ORDER_INFO } from "../../utils/mutations";
import { ORDER } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";

export default function TrackingForm(props) {
  const { code, carrier, cost, orderId, disabled } = props;

  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, setValue } = useForm();

  const [changeOrderInfo] = useMutation(CHANGE_ORDER_INFO, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Tracking info updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { code, carrier, cost } = data.order.tracking;

    changeOrderInfo({
      variables: {
        inputs: {
          id: orderId,
          code,
          carrier,
          cost: parseInt(cost * 100),
        },
      },
    });
  };

  useEffect(() => {
    if (code) setValue("order.tracking.code", code);
    if (carrier) setValue("order.tracking.carrier", carrier);
    if (cost) setValue("order.tracking.cost", cost / 100);
  }, [code, carrier, cost, setValue]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* code */}
        <div>
          <label htmlFor="code" className="label">
            <span className="label-text font-semibold">Code</span>
          </label>
          <input
            type="text"
            name="code"
            id="code"
            className="input input-bordered w-full"
            {...register("order.tracking.code")}
            disabled={disabled}
          />
        </div>
        {/* carrier */}
        <div>
          <label htmlFor="carrier" className="label">
            <span className="label-text font-semibold">Carrier</span>
          </label>
          <input
            type="text"
            name="carrier"
            id="carrier"
            className="input input-bordered w-full"
            {...register("order.tracking.carrier")}
            disabled={disabled}
          />
        </div>
        {/* cost */}
        <div>
          <label htmlFor="cost" className="label">
            <span className="label-text font-semibold">Cost (in AUD)</span>
          </label>
          <input
            type="number"
            min="0.00"
            max="10000.00"
            step="0.01"
            name={`cost`}
            className="input input-bordered w-full"
            {...register("order.tracking.cost")}
            disabled={disabled}
          />
        </div>
        {/* update */}
        {!disabled && (
          <div className="pt-2">
            <button
              className={
                loader
                  ? "btn btn-sm btn-outline loading"
                  : "btn btn-sm btn-outline"
              }
            >
              Update
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
