import { useState, useEffect, useRef } from "react";
import AWS from "aws-sdk";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import { UPDATE_THUMBNAIL } from "../../utils/mutations";
import { PRODUCTS, PRODUCT } from "../../utils/queries";
import { useMutation } from "@apollo/client";

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function ThumbnailForm(props) {
  const { info, id } = props;

  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileExtension, setFileExtension] = useState("jpg");

  const editor = useRef(null);

  const [updateProductThumbnail] = useMutation(UPDATE_THUMBNAIL, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Product thumbnail updated!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: PRODUCTS },
      { query: PRODUCT, variables: { productId: id } },
    ],
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `thumbnail/${id}.${fileExtension}`,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on("complete", () => {
        updateProductThumbnail({
          variables: {
            inputs: {
              id: id,
              url: `${id}.${fileExtension}`,
            },
          },
        });
      })
      .send((err) => {
        if (err) toast.error(err.message);
      });
  };

  const getImageUrl = async () => {
    const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    const file = new File([blob], `${id}.${fileExtension}`);

    return file;
  };

  useEffect(() => {
    if (info?.thumbnail) {
      setSelectedFile(thumbnailUri(info.thumbnail));
      var extension = info.thumbnail.substring(
        info.thumbnail.lastIndexOf(".") + 1
      );
      setFileExtension(extension);
    }
  }, [info]);

  return (
    <div>
      <div>
        <h3 className="text-lg pb-4">
          Set product thumbnail image for:
          <br />
          <span className="font-semibold italic">{info?.title}</span>
        </h3>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Dropzone
          onDrop={(dropped) => setSelectedFile(dropped[0])}
          noClick={true}
          noKeyboard
          maxFiles={1}
          style={{ width: "600px", height: "600px" }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                ref={editor}
                image={selectedFile}
                width={250}
                height={250}
                disableHiDPIScaling={true}
              />
              <input {...getInputProps()} />
              <p>Drag 'n' drop an image here, or click to select files</p>
            </div>
          )}
        </Dropzone>

        <div>File Upload Progress is {progress}%</div>
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleFileInput}
        />
        <div className="pt-4">
          <button
            className={loader ? "btn loading" : "btn"}
            onClick={async () => {
              if (editor) {
                const image = await getImageUrl();
                uploadFile(image);
              }
            }}
          >
            Upload
          </button>
        </div>
      </form>
    </div>
  );
}

const thumbnailUri = (thumbnail) => {
  if (thumbnail)
    return `https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/${thumbnail}`;
  return "https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/placeholder-square.jpg";
};
