import { Link } from "react-router-dom";

export default function AddressInfo(props) {
  const { address, type, customerId } = props;

  return (
    <div key={address.id} className="py-1">
      <div className="flex justify-between">
        <div className="flex flex-col py-2">
          <p className="pb-2">
            <span className="text-sm font-semibold">
              # {type}: {address.type}
            </span>
          </p>
          {address.company && (
            <p>
              <span className="font-semibold">
                Company:
                <br />{" "}
              </span>
              <span>{address.company}</span>
            </p>
          )}
          {address.phone && (
            <p>
              <span className="font-semibold">
                Phone:
                <br />{" "}
              </span>
              <span>{address.phone}</span>
            </p>
          )}
          {address.street && (
            <p>
              <span className="font-semibold">
                Street:
                <br />{" "}
              </span>
              <span>{address.street}</span>
            </p>
          )}
          {address.additional && (
            <p>
              <span className="font-semibold">
                Additional:
                <br />{" "}
              </span>
              <span>{address.additional}</span>
            </p>
          )}
          {address.suburb && (
            <p>
              <span className="font-semibold">
                Suburb:
                <br />{" "}
              </span>
              <span>{address.suburb}</span>
            </p>
          )}
          {address.state && (
            <p>
              <span className="font-semibold">
                State:
                <br />{" "}
              </span>
              <span>{address.state}</span>
            </p>
          )}
          {address.postcode && (
            <p>
              <span className="font-semibold">
                Postcode:
                <br />{" "}
              </span>
              <span>{address.postcode}</span>
            </p>
          )}
          {address.country && (
            <p>
              <span className="font-semibold">Country: </span>
              <span>{address.country}</span>
            </p>
          )}
          {address.note && (
            <p>
              <span className="font-semibold">Note: </span>
              <span>{address.note}</span>
            </p>
          )}
          {customerId && (
            <div className="space-x-2 pt-2">
              <Link
                to={`/update/address/${address.id}?customer=${customerId}`}
                className="btn btn-xs btn-outline"
              >
                Update
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
