import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CREATE_PRODUCT, UPDATE_PRODUCT } from "../../utils/mutations";
import { PRODUCTS, PRODUCT } from "../../utils/queries";
import { useMutation } from "@apollo/client";

export default function ProductForm(props) {
  const [loader, setLoader] = useState(false);

  const { mode, info, id } = props;

  const { register, handleSubmit, setValue, reset } = useForm();

  // create
  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Product created successfully!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: PRODUCTS }],
  });
  // update
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Product updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: PRODUCTS },
      { query: PRODUCT, variables: { productId: id } },
    ],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { title, sku, width, height, depth, weight, status } = data.product;
    if (mode === "create") {
      createProduct({
        variables: {
          inputs: {
            title,
            sku,
            width: parseInt(width * 10),
            height: parseInt(height * 10),
            depth: parseInt(depth * 10),
            weight: parseInt(weight * 1000),
          },
        },
      });
    } else if (mode === "update") {
      updateProduct({
        variables: {
          inputs: {
            id: id,
            title,
            sku,
            width: parseInt(width * 10),
            height: parseInt(height * 10),
            depth: parseInt(depth * 10),
            weight: parseInt(weight * 1000),
            status,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (info) {
      setValue("product.title", info.title);
      setValue("product.sku", info.sku);
      setValue("product.width", info.package.width / 10);
      setValue("product.height", info.package.height / 10);
      setValue("product.depth", info.package.depth / 10);
      setValue("product.weight", info.package.weight / 1000);
      setValue("product.status", info.status);
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Title</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="input input-bordered w-full"
            required
            {...register("product.title")}
          />
        </div>
        {/* sku */}
        <div>
          <label htmlFor="sku" className="label">
            <span className="label-text font-semibold">SKU</span>
          </label>
          <input
            type="text"
            name="sku"
            id="sku"
            className="input input-bordered w-full"
            required
            {...register("product.sku")}
          />
        </div>
        {/* width */}
        <div>
          <label htmlFor="width" className="label">
            <span className="label-text font-semibold">Width (in cm)</span>
          </label>
          <input
            type="text"
            name="width"
            id="width"
            className="input input-bordered w-full"
            required
            {...register("product.width")}
          />
        </div>
        {/* height */}
        <div>
          <label htmlFor="height" className="label">
            <span className="label-text font-semibold">Height (in cm)</span>
          </label>
          <input
            type="text"
            name="height"
            id="height"
            className="input input-bordered w-full"
            required
            {...register("product.height")}
          />
        </div>
        {/* depth */}
        <div>
          <label htmlFor="depth" className="label">
            <span className="label-text font-semibold">Depth (in cm)</span>
          </label>
          <input
            type="text"
            name="depth"
            id="depth"
            className="input input-bordered w-full"
            required
            {...register("product.depth")}
          />
        </div>
        {/* weight */}
        <div>
          <label htmlFor="weight" className="label">
            <span className="label-text font-semibold">Weight (in kg)</span>
          </label>
          <input
            type="text"
            name="weight"
            id="weight"
            className="input input-bordered w-full"
            required
            {...register("product.weight")}
          />
        </div>
        {/* status */}
        {mode === "update" && (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              defaultValue={"Select"}
              className="select select-bordered w-full"
              required
              {...register("product.status")}
            >
              <option disabled>Select</option>
              <option value="AVAILABLE">Available</option>
              <option value="OUT OF STOCK">Out of Stock</option>
              <option value="UNAVAILABLE">Unavailable</option>
            </select>
          </div>
        )}
        {/* submit */}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
