import { useState, useEffect } from "react";
import { ORDERS } from "../../utils/queries";
import { ACKNOWLEDGE_PRINT_SLIP } from "../../utils/mutations";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";
import { generateSlip } from "../../utils/generateSlip";

export default function OrdersTable(props) {
  const { admin = false } = props;

  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(resultsPerPage);
  const [vendors, setVendors] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState({
    vendor: searchParams.get("vendor") || "ALL",
    status: searchParams.get("status") || "ALL",
  });

  const { loading } = useQuery(ORDERS, {
    onCompleted: (data) => {
      toast.dismiss();
      if (data?.orders) {
        setResults(data.orders);
        setResponse(data.orders);
        setVendors(data.vendors);
        setStatuses([
          "DRAFT",
          "SUBMITTED",
          "FULFILLED",
          "CANCELLED",
          "RETURNED",
        ]);
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    variables: {
      status:
        searchParams.get("status") !== "ALL"
          ? searchParams.get("status")
          : null,
      vendor:
        searchParams.get("vendor") !== "ALL"
          ? searchParams.get("vendor")
          : null,
    },
    pollInterval: 60000,
  });

  const [filteredData, { loading: reloading }] = useLazyQuery(ORDERS, {
    onCompleted: (data) => {
      toast.dismiss();
      if (data?.orders) {
        setResults(data.orders);
        setResponse(data.orders);
        setCurrentPage(1);
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    variables: {
      status: filter.status !== "ALL" ? filter.status : null,
      vendor: filter.vendor !== "ALL" ? filter.vendor : null,
    },
  });

  const [acknowledgePrintSlip, { loading: printing }] = useMutation(
    ACKNOWLEDGE_PRINT_SLIP,
    {
      onCompleted: (data) => {
        toast.dismiss();
        if (data?.order) {
          generateSlip(data);
        }
      },
      onError: (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
    }
  );

  const sliceData = (data, page, rows) => {
    setFrom(data.length > 0 ? (page - 1) * rows + 1 : 0);
    setTo(page * rows > data.length ? data.length : page * rows);
    return data.slice((page - 1) * rows, page * rows);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "vendor") setFilter({ ...filter, vendor: value });
    if (name === "status") setFilter({ ...filter, status: value });
    filteredData();
  };

  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    if (keyword.length > 0) {
      const arr = response.filter((item) => {
        return (
          item.number?.toLowerCase().includes(keyword.toLowerCase()) ||
          item.ref?.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      setResults(arr);
      setCurrentPage(1);
    } else {
      setResults(response);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handlePrintSlip = (id) => {
    acknowledgePrintSlip({ variables: { orderId: id } });
  };

  useEffect(() => {
    if (loading || reloading || printing) toast.loading("Loading...");
  }, [loading, reloading, printing]);

  useEffect(() => {
    if (results) setTotalPages(Math.ceil(results.length / resultsPerPage));
  }, [results, resultsPerPage]);

  useEffect(() => {
    const slice = sliceData(results, currentPage, resultsPerPage);
    setList([...slice]);
  }, [results, currentPage, setList, resultsPerPage]);

  useEffect(() => {
    if (filter) setSearchParams(filter);
  }, [filter, setSearchParams]);

  return (
    <div className="space-y-6">
      {/* table filter */}
      {response && (
        <div className="grid grid-flow-row xl:grid-flow-col px-2 gap-4">
          {/* search */}
          <div className="col-span-1">
            <input
              name="keyword"
              className="col-span-1 input input-bordered w-full max-w-md bg-white ring-primary-focus form-control"
              type="search"
              defaultValue={filter.keyword}
              placeholder="Filter by Order No. / Ref."
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-span-1 flex flex-row justify-end gap-4">
            {/* vendor */}
            <select
              name="vendor"
              className="select select-bordered w-auto md:w-64"
              onChange={handleFilterChange}
              value={filter.vendor}
            >
              <option value="ALL">All Vendors</option>
              {vendors.length > 0 &&
                vendors.map((vendor, index) => (
                  <option key={index} value={vendor.id}>
                    {vendor.dealer}
                  </option>
                ))}
            </select>
            {/* status */}
            <select
              name="status"
              className="select select-bordered w-auto md:w-64"
              onChange={handleFilterChange}
              value={filter.status}
            >
              <option value="ALL">All Statuses</option>
              {statuses.length > 0 &&
                statuses.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
      <div className="overflow-x-auto rounded border-solid border-2">
        <table className="table-normal divide-y-2 bg-white rounded w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th className="text-center">#</th>
              <th>Number</th>
              <th>Vendor</th>
              <th>Customer</th>
              <th>Date</th>
              <th className="text-center">Status</th>
              <th>Total</th>
              {admin && <th>Invoice #</th>}
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list &&
              list.map((value, index) => (
                <tr key={value.id}>
                  <th>{from + index}</th>
                  <td>
                    <Link to={`/order/${value.id}`}>
                      <span className="font-bold underline">
                        {value.number ? value.number : "-"}
                      </span>
                      <br />
                      <span className="text-sm">Ref: {value.ref}</span>
                    </Link>
                  </td>
                  <td>
                    <span>{value.vendor.dealer}</span>
                    <br />
                    <span className="text-sm">Source: {value.source.name}</span>
                  </td>
                  <td className="max-w-sm whitespace-normal">
                    <span>{value.customer.name}</span>
                  </td>
                  <td>
                    <span>
                      {moment(value.date / 1000, "X").format("MMM DD, yy")}
                    </span>
                  </td>
                  <td className="text-center">
                    <span
                      className={`text-xs py-1 px-2 text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>{formatter.format(value.total / 100)}</td>
                  {admin && (
                    <td>
                      {value.invoice ? (
                        <span>{value.invoice}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  )}
                  <td className="text-center">
                    {value.status === "SUBMITTED" && (
                      <button
                        className={`btn btn-sm btn-ghost ${
                          value.printed ? "btn-disabled" : ""
                        }`}
                        onClick={() => handlePrintSlip(value.id)}
                      >
                        Print Slip
                      </button>
                    )}
                    {value.status !== "SUBMITTED" && <p>-</p>}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* table pagination */}
      <div className="flex w-full px-2">
        {results && (
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{from}</span> -{" "}
                <span className="font-bold">{to}</span> of{" "}
                <span className="font-bold">{results.length}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === 1 || totalPages === 0}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function statusTagColor(str) {
  if (str === "DRAFT") return `bg-warning`;
  if (str === "SUBMITTED") return `bg-info`;
  if (str === "FULFILLED") return `bg-success`;
  if (str === "RETURNED") return `bg-error`;
  return `bg-base-300`;
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});
