import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_ORDER_CUSTOMER,
} from "../../utils/mutations";
import { CUSTOMERS, CUSTOMER, ORDER } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import { Countries } from "../../utils/constants";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function CustomerForm(props) {
  const [loader, setLoader] = useState(false);

  const { mode, info, id } = props;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [orderId] = useState(searchParams.get("order"));

  const { register, handleSubmit, setValue, reset } = useForm();

  // create
  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer created successfully!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: CUSTOMERS }],
  });
  // update
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: CUSTOMERS },
      { query: CUSTOMER, variables: { customerId: id } },
    ],
  });
  // create order customer
  const [createOrderCustomer] = useMutation(CREATE_ORDER_CUSTOMER, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer created successfully!");
      navigate(`/order/${orderId}`);
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: CUSTOMERS },
      { query: ORDER, variables: { orderId: orderId } },
    ],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { name, email, address } = data.customer;
    const {
      type,
      phone,
      company,
      street,
      additional,
      suburb,
      state,
      postcode,
      country,
      note,
    } = address;
    if (mode === "create") {
      if (orderId) {
        createOrderCustomer({
          variables: {
            inputs: {
              name,
              email,
              type,
              phone,
              company,
              street,
              additional,
              suburb,
              state,
              postcode,
              country,
              note,
            },
            orderId: orderId,
          },
        });
      } else {
        createCustomer({
          variables: {
            inputs: {
              name,
              email,
              type,
              phone,
              company,
              street,
              additional,
              suburb,
              state,
              postcode,
              country,
              note,
            },
          },
        });
      }
    } else if (mode === "update") {
      updateCustomer({
        variables: {
          inputs: {
            id: id,
            name,
            email,
            type,
            phone,
            company,
            street,
            additional,
            suburb,
            state,
            postcode,
            country,
            note,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (info) {
      setValue("customer.name", info.name);
      setValue("customer.email", info.email);
      setValue("customer.address.type", info.address.type);
      setValue("customer.address.phone", info.address.phone);
      setValue("customer.address.company", info.address.company);
      setValue("customer.address.street", info.address.street);
      setValue("customer.address.addtional", info.address.additional);
      setValue("customer.address.state", info.address.state);
      setValue("customer.address.suburb", info.address.suburb);
      setValue("customer.address.postcode", info.address.postcode);
      setValue("customer.address.country", info.address.country);
      setValue("customer.address.note", info.address.note);
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="input input-bordered w-full"
            required
            {...register("customer.name")}
          />
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="input input-bordered w-full"
            {...register("customer.email")}
          />
        </div>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400">
            Default address
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        {/* type */}
        <div>
          <label htmlFor="type" className="label">
            <span className="label-text font-semibold">Type</span>
          </label>
          <select
            name="type"
            id="type"
            className="input input-bordered w-full"
            required
            {...register("customer.address.type")}
          >
            <option value="RESIDENTIAL">Residential</option>
            <option value="COMMERCIAL">Commercial</option>
          </select>
        </div>
        {/* phone */}
        <div>
          <label htmlFor="phone" className="label">
            <span className="label-text font-semibold">Phone</span>
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            className="input input-bordered w-full"
            required
            {...register("customer.address.phone")}
          />
        </div>
        {/* company */}
        <div>
          <label htmlFor="company" className="label">
            <span className="label-text font-semibold">Company</span>
          </label>
          <input
            type="text"
            name="company"
            id="company"
            className="input input-bordered w-full"
            {...register("customer.address.company")}
          />
        </div>
        {/* street */}
        <div>
          <label htmlFor="street" className="label">
            <span className="label-text font-semibold">Street</span>
          </label>
          <input
            type="text"
            name="street"
            id="street"
            className="input input-bordered w-full"
            required
            {...register("customer.address.street")}
          />
        </div>
        {/* addtional */}
        <div>
          <label htmlFor="additional" className="label">
            <span className="label-text font-semibold">Additional</span>
          </label>
          <input
            type="text"
            name="additional"
            id="additional"
            className="input input-bordered w-full"
            {...register("customer.address.additional")}
          />
        </div>
        {/* suburb */}
        <div>
          <label htmlFor="suburb" className="label">
            <span className="label-text font-semibold">Suburb</span>
          </label>
          <input
            type="text"
            name="suburb"
            id="suburb"
            className="input input-bordered w-full"
            required
            {...register("customer.address.suburb")}
          />
        </div>
        {/* state */}
        <div>
          <label htmlFor="state" className="label">
            <span className="label-text font-semibold">State</span>
          </label>
          <select
            name="type"
            id="type"
            className="input input-bordered w-full"
            required
            {...register("customer.address.state")}
          >
            <option value="NSW">NSW</option>
            <option value="VIC">VIC</option>
            <option value="QLD">QLD</option>
            <option value="WA">WA</option>
            <option value="SA">SA</option>
            <option value="TAS">TAS</option>
            <option value="ACT">ACT</option>
            <option value="NT">NT</option>
          </select>
        </div>
        {/* postcode */}
        <div>
          <label htmlFor="postcode" className="label">
            <span className="label-text font-semibold">Postcode</span>
          </label>
          <input
            type="text"
            name="postcode"
            id="postcode"
            className="input input-bordered w-full"
            required
            {...register("customer.address.postcode")}
          />
        </div>
        {/* country */}
        <div>
          <label htmlFor="country" className="label">
            <span className="label-text font-semibold">Country</span>
          </label>
          <select
            name="country"
            id="country"
            defaultValue={"AU"}
            className="input input-bordered w-full"
            required
            {...register("customer.address.country")}
          >
            {Countries &&
              Countries.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.label}
                </option>
              ))}
          </select>
        </div>
        {/* note */}
        <div>
          <label htmlFor="note" className="label">
            <span className="label-text font-semibold">Note</span>
          </label>
          <textarea
            name="note"
            id="note"
            className="input input-bordered w-full"
            {...register("customer.address.note")}
          />
        </div>
        {/* submit */}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
