import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import CustomerInfo from "../library/info/Customer";
import AddressesList from "../library/list/Address";
import PurchasesTable from "../library/table/Purchases";
import { CUSTOMER, CUSTOMERS } from "../utils/queries";
import { DELETE_CUSTOMER } from "../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

function Customer(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { loading, data } = useQuery(CUSTOMER, {
    variables: { customerId: id },
    onCompleted: () => {
      toast.dismiss();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    onCompleted: () => {
      navigate("/customers");
      toast.success("Customer deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: CUSTOMERS }],
  });

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete the customer?")) {
      deleteCustomer({
        variables: {
          customerId: id,
        },
      });
    }
  };

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  return (
    <UserLayout title={`Customer Information`}>
      <HeadSection heading={`Customer Information`}>
        <Link to={`/update/customer/${id}`} className="btn btn-outline">
          Update
        </Link>
        <button className="btn btn-error btn-outline" onClick={handleDelete}>
          Delete
        </button>
        <Link to={`/customers`} className="btn btn-outline">
          Back
        </Link>
      </HeadSection>
      {data && (
        <section className="pt-8 space-y-8">
          <div className="grid grid-flow-row lg:grid-flow-col gap-6">
            <div className="col-span-1">
              <div className="grid grid-flow-row gap-6">
                <div>
                  <h2 className="text-xl font-semibold p-2">Information</h2>
                  <CustomerInfo
                    name={data?.customer?.name}
                    email={data?.customer?.email}
                  />
                </div>
                <div>
                  <h2 className="text-xl font-semibold p-2">Addresses</h2>
                  {id && (
                    <div className="p-2">
                      <Link
                        to={`/create/address/?customer=${id}`}
                        className="btn btn-xs btn-outline"
                      >
                        Add new
                      </Link>
                    </div>
                  )}
                  <AddressesList
                    id={data?.customer?.id}
                    addresses={data?.customer?.addresses}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-5">
              <h2 className="text-xl font-semibold p-2">Purchase history</h2>
              <PurchasesTable purchases={data?.customer?.purchases} />
            </div>
          </div>
        </section>
      )}
    </UserLayout>
  );
}

export default Customer;
