import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        role
      }
    }
  }
`;

export const RESET = gql`
  mutation Reset($email: String!) {
    reset(email: $email)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($inputs: user) {
    updated: updateProfile(inputs: $inputs)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($password: String!) {
    updated: changePassword(password: $password)
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($inputs: user) {
    created: createUser(inputs: $inputs)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($inputs: user) {
    updated: updateUser(inputs: $inputs)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($userId: ID) {
    updated: resetPassword(id: $userId)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID) {
    deleted: deleteUser(id: $userId)
  }
`;

export const CREATE_VENDOR = gql`
  mutation CreateVendor($inputs: vendor) {
    created: createVendor(inputs: $inputs)
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($inputs: vendor) {
    updated: updateVendor(inputs: $inputs)
  }
`;

export const CREATE_VENDOR_SOURCE = gql`
  mutation CreateSource($inputs: source) {
    created: createSource(inputs: $inputs)
  }
`;

export const UPDATE_VENDOR_SOURCE = gql`
  mutation UpdateSource($inputs: source) {
    updated: updateSource(inputs: $inputs)
  }
`;

export const DELETE_VENDOR_SOURCE = gql`
  mutation DeleteSource($sourceId: ID!) {
    deleted: deleteSource(id: $sourceId)
  }
`;

export const DELETE_VENDOR = gql`
  mutation DeleteVendor($vendorId: ID!) {
    deleted: deleteVendor(id: $vendorId)
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($inputs: product) {
    created: createProduct(inputs: $inputs)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($inputs: product) {
    updated: updateProduct(inputs: $inputs)
  }
`;

export const UPDATE_THUMBNAIL = gql`
  mutation UpdateThumbnail($inputs: thumbnail) {
    updated: updateThumbnail(inputs: $inputs)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: ID!) {
    deleted: deleteProduct(id: $productId)
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($inputs: customer) {
    created: createCustomer(inputs: $inputs)
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($inputs: customer) {
    updated: updateCustomer(inputs: $inputs)
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress($inputs: address) {
    created: createCustomerAddress(inputs: $inputs)
  }
`;

export const UPDATE_DEFAULT_CUSTOMER_ADDRESS = gql`
  mutation UpdateDefaultCustomerAddress($inputs: address) {
    updated: updateDefaultCustomerAddress(inputs: $inputs)
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($inputs: address) {
    updated: updateCustomerAddress(inputs: $inputs)
  }
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation DeleteCustomerAddress($addressId: ID!) {
    deleted: deleteCustomerAddress(id: $addressId)
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($customerId: ID) {
    deleted: deleteCustomer(id: $customerId)
  }
`;

export const DRAFT_ORDER = gql`
  mutation DraftOrder($inputs: draft) {
    created: draftOrder(inputs: $inputs)
  }
`;

export const SUBMIT_ORDER = gql`
  mutation SubmitOrder($orderId: ID!) {
    updated: submitOrder(id: $orderId)
  }
`;

export const REVERT_ORDER = gql`
  mutation RevertOrder($orderId: ID!) {
    updated: revertOrder(id: $orderId)
  }
`;

export const DELETE_ORDER = gql`
  mutation DeleteOrder($orderId: ID!) {
    deleted: deleteOrder(id: $orderId)
  }
`;

export const FULFILL_ORDER = gql`
  mutation FulfillOrder($orderId: ID!) {
    updated: fulfillOrder(id: $orderId)
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($orderId: ID!) {
    updated: cancelOrder(id: $orderId)
  }
`;

export const RETURN_ORDER = gql`
  mutation ReturnOrder($orderId: ID!) {
    updated: returnOrder(id: $orderId)
  }
`;

export const CREATE_ORDER_CUSTOMER = gql`
  mutation Mutation($orderId: ID!, $inputs: customer) {
    created: createOrderCustomer(orderId: $orderId, inputs: $inputs)
  }
`;

export const CHANGE_ORDER_INFO = gql`
  mutation ChangeOrderInfo($inputs: order) {
    updated: changeOrderInfo(inputs: $inputs)
  }
`;

export const ASSOCIATE_ORDER_CUSTOMER = gql`
  mutation AssociateOrderCustomer($inputs: order) {
    updated: associateOrderCustomer(inputs: $inputs)
  }
`;

export const ASSOCIATE_ORDER_ADDRESS = gql`
  mutation AssociateOrderAddress($inputs: order) {
    updated: associateOrderAddress(inputs: $inputs)
  }
`;

export const ADD_ORDER_ITEM = gql`
  mutation AddOrderItem($inputs: item) {
    created: addOrderItem(inputs: $inputs)
  }
`;

export const UPDATE_ORDER_ITEM = gql`
  mutation UpdateOrderItem($inputs: item) {
    updated: updateOrderItem(inputs: $inputs)
  }
`;

export const REMOVE_ORDER_ITEM = gql`
  mutation RemoveOrderItem($inputs: item) {
    deleted: removeOrderItem(inputs: $inputs)
  }
`;

export const ACKNOWLEDGE_PRINT_SLIP = gql`
  mutation PrintOrderSlip($orderId: ID!) {
    order: printOrderSlip(id: $orderId) {
      id
      number
      ref
      date
      customer {
        name
      }
      shipping {
        street
        additional
        suburb
        state
        postcode
        country
      }
      billing {
        street
        additional
        suburb
        state
        postcode
        country
      }
      items {
        description
        product {
          sku
        }
        quantity
      }
      vendor {
        dealer
        address
        abn
      }
      source {
        url
      }
      printed
    }
  }
`;
