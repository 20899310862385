import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CHANGE_ORDER_INFO } from "../../utils/mutations";
import { ORDER } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";

export default function InvoiceForm(props) {
  const { invoice, orderId } = props;

  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, setValue } = useForm();

  const [changeOrderInfo] = useMutation(CHANGE_ORDER_INFO, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Note updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { invoice } = data.order;
    changeOrderInfo({
      variables: {
        inputs: {
          id: orderId,
          invoice,
        },
      },
    });
  };

  useEffect(() => {
    if (invoice) setValue("order.invoice", invoice);
  }, [invoice, setValue]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row gap-4">
        <div>
          <input
            type="text"
            name="invoice"
            id="invoice"
            className="input input-bordered w-full"
            {...register("order.invoice")}
          />
        </div>
        {/* update */}
        <div className="pt-2">
          <button
            className={
              loader ? "btn btn-sm btn-ghost loading" : "btn btn-sm btn-ghost"
            }
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
