import { Link } from "react-router-dom";
import { DELETE_VENDOR_SOURCE } from "../../utils/mutations";
import { VENDOR } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export default function SourcesTable(props) {
  const { sources, vendorId } = props;

  const [deleteSource] = useMutation(DELETE_VENDOR_SOURCE, {
    onCompleted: () => {
      toast.success("Source deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: VENDOR, variables: { vendorId: vendorId } }],
  });

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the source?")) {
      deleteSource({
        variables: {
          sourceId: id,
        },
      });
    }
  };

  return (
    <div className="overflow-x-auto rounded border-solid border-2">
      <table className="table-normal divide-y-2 bg-white rounded w-full">
        {/* head */}
        <thead className="text-left">
          <tr>
            <th className="text-center">#</th>
            <th>Name</th>
            <th>URL</th>
            <th className="text-center">Is default?</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="divide-y-2">
          {sources &&
            sources.map((value, index) => (
              <tr key={value.id}>
                <th>{index + 1}</th>
                <td>
                  <span>{value.name}</span>
                </td>
                <td>{value.url ?? "-"}</td>
                <td className="text-center">{value.default ? `Yes` : `No`}</td>
                <td>
                  <Link
                    to={`/update/source/${value.id}?vendor=${vendorId}`}
                    className="btn btn-xs btn-ghost"
                  >
                    Update
                  </Link>
                  {!value.default && (
                    <button
                      className="btn btn-xs btn-ghost"
                      onClick={() => handleDelete(value.id)}
                    >
                      Remove
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
