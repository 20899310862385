import { Link } from "react-router-dom";
import PublicLayout from "../layout/Public";
import LoginForm from "../library/form/Login";

function Login(props) {
  return (
    <PublicLayout title="Admin Login | Aimex Australia App">
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">Login</h1>
        <div className="text-center pt-4">Management access</div>
      </div>
      <LoginForm />
      <div className="text-center pt-6">
        <Link to="/reset" className="text-secondary">
          Forgot password?
        </Link>
      </div>
    </PublicLayout>
  );
}

export default Login;
