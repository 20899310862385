import { Link } from "react-router-dom";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import CustomersTable from "../library/table/Customers";

function Customers(props) {
  return (
    <UserLayout title={`Customers`}>
      <HeadSection heading="Customers">
        <Link to={"/create/customer"} className="btn btn-primary">
          New Customer
        </Link>
      </HeadSection>
      <section className="pt-8 space-y-8">
        <CustomersTable />
      </section>
    </UserLayout>
  );
}

export default Customers;
