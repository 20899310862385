import moment from "moment";
import { Link } from "react-router-dom";

export default function OrderStats(props) {
  const { list } = props;

  return (
    <div className="overflow-x-auto shadow w-full p-2 border-solid border-2">
      <table className="table w-full">
        <tbody className="divide-y-2">
          {list &&
            list.map((value, index) => (
              <tr key={value.id}>
                <th>{index + 1}</th>
                <td>
                  <Link to={`/order/${value.id}`}>
                    <span className="font-bold underline">
                      {value.number ? value.number : "-"}
                    </span>
                    <br />
                    <span className="text-sm">Ref: {value.ref}</span>
                  </Link>
                </td>
                <td>
                  <span>{value.vendor}</span>
                  <br />
                  <span className="text-sm">Source: {value.source}</span>
                </td>
                <td>{value.customer}</td>
                <td>{moment(value.date / 1000, "X").format("LL")}</td>
                <th>{formatter.format(value.total / 100)}</th>
                <th>
                  <Link
                    to={`/order/${value.id}`}
                    className="btn btn-ghost btn-sm"
                  >
                    Details
                  </Link>
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});
