export default function SourcesStats(props) {
  const { list } = props;

  return (
    <div className="overflow-x-auto shadow w-full border-solid border-2">
      <table className="table w-full">
        <tbody className="divide-y-2">
          {list &&
            list.map((value, index) => (
              <tr key={value.id}>
                <th>{index + 1}</th>
                <td>
                  {value.name} <span>({value.dealer})</span>
                </td>
                <th>{formatter.format(value.total / 100)}</th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  notation: "compact",
});
