export default function VendorInfo(props) {
  const { vendor } = props;
  const { name, email, dealer, tier, entity, abn, address, status, owned } =
    vendor;

  return (
    <div>
      <div>
        <div className="divide-y-2">
          {/* name */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Name:</span>
              <br /> <span>{name}</span>
            </p>
          </div>
          {/* email */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Email:</span>
              <br /> <span>{email}</span>
            </p>
          </div>
          {/* tier */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Tier:</span>
              <br /> <span>{tier}</span>
            </p>
          </div>
          {/* dealer */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Dealer name:</span>
              <br /> <span>{dealer}</span>
            </p>
          </div>
          {/* entity */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Entity name:</span>
              <br /> <span>{entity}</span>
            </p>
          </div>
          {/* abn */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">ABN:</span>
              <br /> <span>{abn}</span>
            </p>
          </div>
          {/* address */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Address:</span>
              <br /> <span>{address}</span>
            </p>
          </div>
          {/* owned */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Is aimex owned?</span>
              <br /> <span>{owned ? `Yes` : `No`}</span>
            </p>
          </div>
          {/* status */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Status:</span>
              <br />{" "}
              <span
                className={`text-xs py-1 px-2 text-white ${statusTagColor(
                  status
                )}`}
              >
                {status}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function statusTagColor(str) {
  if (str === "ACTIVE") return `bg-info`;
  return `bg-base-300`;
}
