import { Link } from "react-router-dom";
import moment from "moment";

export default function PurchasesTable(props) {
  const { purchases } = props;

  return (
    <div className="overflow-x-auto rounded border-solid border-2">
      <table className="table-normal divide-y-2 bg-white rounded w-full">
        {/* head */}
        <thead className="text-left">
          <tr>
            <th className="text-center">#</th>
            <th>Item</th>
            <th>Price</th>
            <th className="text-center">Qty.</th>
            <th className="text-center">Order</th>
            <th className="text-center">Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody className="divide-y-2">
          {purchases &&
            purchases.map((value, index) => (
              <tr key={value.id}>
                <th>{index + 1}</th>
                <td className="flex gap-4 content-center">
                  <div>
                    <img
                      src={thumbnailUri(value.product.thumbnail)}
                      alt={value.product.title}
                      className="w-12 h-12"
                    />
                  </div>
                  <div>
                    <span className="font-bold">{value.description}</span>
                    <br />
                    <span>{value.product.sku}</span>
                  </div>
                </td>
                <td>
                  <span>{formatter.format(value.price / 100)}</span>
                </td>
                <td className="text-center">{value.quantity}</td>
                <td className="text-center">
                  <Link
                    to={`/order/${value.order.id}`}
                    className="font-semibold underline"
                  >
                    {value.order.number}
                  </Link>
                </td>
                <td className="text-center">
                  <span
                    className={`text-xs py-1 px-2 text-white ${statusTagColor(
                      value.order.status
                    )}`}
                  >
                    {value.order.status}
                  </span>
                </td>
                <td>{moment(value.order.date / 1000, "X").format("LL")}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const thumbnailUri = (thumbnail) => {
  if (thumbnail)
    return `https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/${thumbnail}`;
  return "https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/placeholder-square.jpg";
};

function statusTagColor(str) {
  if (str === "DRAFT") return `bg-warning`;
  if (str === "SUBMITTED") return `bg-info`;
  if (str === "FULFILLED") return `bg-success`;
  if (str === "RETURNED") return `bg-error`;
  return `bg-base-300`;
}
