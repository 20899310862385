import { useState, useContext } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import UserForm from "../library/form/User";
import VendorFrom from "../library/form/Vendor";
import ProductForm from "../library/form/Product";
import CustomerForm from "../library/form/Customer";
import AddressForm from "../library/form/Address";
import OrderForm from "../library/form/Order";
import SourceForm from "../library/form/Source";

function Create(props) {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");
  const [isManager] = useState(user.data.role === "MANAGER");

  const navigate = useNavigate();
  const { type } = useParams();

  return (
    <UserLayout title={`Create ${type}`}>
      <HeadSection heading={`Create ${type}`}>
        <button className="btn btn-outline" onClick={() => navigate(-1)}>
          Back
        </button>
      </HeadSection>
      <section className="pt-8 space-y-8 max-w-md">
        {type && basedOnType(type, isAdmin, isManager)}
      </section>
    </UserLayout>
  );
}

export default Create;

const basedOnType = (type, isAdmin, isManager) => {
  if (isAdmin) {
    switch (type) {
      // user
      case "user":
        return <UserForm mode="create" />;
      // vendor
      case "vendor":
        return <VendorFrom mode="create" />;
      // source
      case "source":
        return <SourceForm mode="create" />;
      // product
      case "product":
        return <ProductForm mode="create" />;
      // customer
      case "customer":
        return <CustomerForm mode="create" />;
      // address
      case "address":
        return <AddressForm mode="create" />;
      // order
      case "order":
        return <OrderForm mode="create" />;
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  } else if (isManager) {
    switch (type) {
      // product
      case "product":
        return <ProductForm mode="create" />;
      // order
      case "order":
        return <OrderForm mode="create" />;
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  } else {
    switch (type) {
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  }
};
