import { Link } from "react-router-dom";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import UsersTable from "../library/table/Users";

function Users(props) {
  return (
    <UserLayout title={`Users`}>
      <HeadSection heading="Users">
        <Link to={"/create/user"} className="btn btn-primary">
          New User
        </Link>
      </HeadSection>
      <section className="pt-8 space-y-8">
        <UsersTable />
      </section>
    </UserLayout>
  );
}

export default Users;
