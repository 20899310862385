import { USERS } from "../../utils/queries";
import { DELETE_USER } from "../../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export default function UsersTable(props) {
  const { data } = useQuery(USERS, {
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      toast.success("User deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: USERS }],
  });

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the user?")) {
      deleteUser({
        variables: {
          userId: id,
        },
      });
    }
  };

  return (
    <div>
      <div className="overflow-x-auto rounded border-solid border-2">
        <table className="table-normal divide-y-2 bg-white rounded w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th className="text-center">#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.users &&
              data.users.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.name}</td>
                  <td>{value.email}</td>
                  <td>{value.role}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/update/user/${value.id}`}
                      className="btn btn-xs btn-ghost"
                    >
                      Update
                    </Link>
                    <button
                      className="btn btn-xs btn-ghost"
                      onClick={() => handleDelete(value.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function statusTagColor(str) {
  if (str === "ACTIVE") return `bg-info`;
  return `bg-base-300`;
}
