import { gql } from "@apollo/client";

export const PROFILE = gql`
  query Profile {
    profile {
      id
      email
      name
    }
  }
`;

export const USERS = gql`
  query Users {
    users {
      id
      name
      email
      role
      status
    }
  }
`;

export const USER = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      name
      email
      role
      status
      created
      updated
    }
  }
`;

export const VENDORS = gql`
  query Vendors {
    vendors {
      id
      name
      email
      dealer
      tier
      entity
      abn
      address
      sources {
        id
        name
        url
        default
      }
      owned
      status
      created
      updated
    }
  }
`;

export const VENDOR = gql`
  query Vendor($vendorId: ID!) {
    vendor(id: $vendorId) {
      id
      name
      email
      tier
      dealer
      entity
      abn
      address
      sources {
        id
        name
        url
        default
      }
      owned
      status
      created
      updated
    }
  }
`;

export const SOURCE = gql`
  query Source($sourceId: ID!) {
    source(id: $sourceId) {
      id
      name
      url
      default
    }
  }
`;

export const AVAILABLE_VENDORS = gql`
  query AvailableVendors {
    list: availableVendors {
      id
      dealer
      sources {
        id
        name
        url
        default
      }
    }
  }
`;

export const PRODUCTS = gql`
  query Products {
    products {
      id
      title
      sku
      thumbnail
      package {
        width
        height
        depth
        weight
      }
      status
      created
      updated
    }
  }
`;

export const PRODUCT = gql`
  query Product($productId: ID!) {
    product(id: $productId) {
      id
      title
      sku
      thumbnail
      package {
        width
        height
        depth
        weight
      }
      status
      created
      updated
    }
  }
`;

export const FIND_PRODUCTS = gql`
  query FindProducts($title: String!) {
    list: findProducts(title: $title) {
      id
      title
      sku
      thumbnail
      status
    }
  }
`;

export const CUSTOMERS = gql`
  query Customers {
    customers {
      id
      name
      email
      address {
        id
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
        type
      }
      transactions
      created
      updated
    }
  }
`;

export const CUSTOMER = gql`
  query Customer($customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      email
      address {
        id
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
        type
      }
      addresses {
        id
        type
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
        default
        customer
      }
      transactions
      purchases {
        id
        product {
          id
          title
          sku
          thumbnail
        }
        description
        price
        quantity
        amount
        order {
          id
          number
          date
          status
        }
      }
      created
      updated
    }
  }
`;

export const FIND_CUSTOMERS = gql`
  query FindCustomer($email: String!) {
    list: findCustomer(email: $email) {
      id
      name
      email
      address {
        phone
        suburb
        state
        postcode
        country
      }
    }
  }
`;

export const ADDRESS = gql`
  query CustomerAddress($addressId: ID!) {
    address: customerAddress(id: $addressId) {
      id
      type
      phone
      company
      street
      additional
      suburb
      state
      postcode
      country
      note
      default
      customer
    }
  }
`;

export const LIST_ADDRESSES = gql`
  query ListAddresses($customerId: ID!) {
    list: listAddresses(id: $customerId) {
      id
      type
      phone
      company
      street
      additional
      suburb
      state
      postcode
      country
      note
      default
    }
  }
`;

export const ORDERS = gql`
  query Orders($status: String, $vendor: ID) {
    orders(status: $status, vendor: $vendor) {
      id
      number
      ref
      date
      vendor {
        id
        dealer
      }
      source {
        name
      }
      customer {
        name
        email
      }
      total
      status
      invoice
      created
      updated
      printed
    }
    vendors {
      id
      dealer
    }
  }
`;

export const ORDER = gql`
  query Order($orderId: ID!) {
    order(id: $orderId) {
      id
      number
      ref
      date
      status
      total
      vendor {
        id
        dealer
        address
        abn
        email
      }
      source {
        id
        name
        url
      }
      customer {
        name
        email
        id
      }
      billing {
        id
        type
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
      }
      shipping {
        id
        type
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
      }
      items {
        id
        description
        price
        quantity
        amount
        product {
          id
          title
          sku
          thumbnail
          package {
            height
            width
            depth
            weight
          }
        }
      }
      note
      tracking {
        code
        carrier
        cost
      }
      invoice
    }
  }
`;

export const DASHBOARD = gql`
  query Dashboard {
    dashboard {
      salesToday
      salesThisWeek
      salesThisMonth
      salesThisQuarter
      salesThisYear
      ordersSubmittedThisWeek
      ordersFulfilledThisWeek
      availableProducts
      totalCustomers
      topVendors {
        id
        name
        total
      }
      topSources {
        id
        name
        dealer
        total
      }
      ordersToBeFulfilled {
        id
        number
        ref
        date
        vendor
        source
        customer
        total
        submittedAt
      }
    }
  }
`;
