export default function CustomerInfo(props) {
  const { name, email } = props;

  return (
    <div>
      <div>
        <div className="divide-y-2">
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Name:</span>
              <br /> <span>{name}</span>
            </p>
          </div>
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Email:</span>
              <br /> <span>{email}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
