import moment from "moment";

export default function SalesStats(props) {
  const { today, thisWeek, thisMonth, thisQuarter, thisYear } = props;

  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow w-full border-solid border-2">
      <div className="stat">
        <div className="stat-title">Today</div>
        <div className="stat-value">{formatter.format(today / 100)}</div>
        <div className="stat-desc">{todayDate}</div>
      </div>

      <div className="stat">
        <div className="stat-title">This Week</div>
        <div className="stat-value">{formatter.format(thisWeek / 100)}</div>
        <div className="stat-desc">
          {thisWeekStartDate} - {thisWeekEndDate}
        </div>
      </div>

      <div className="stat">
        <div className="stat-title">This Month</div>
        <div className="stat-value">{formatter.format(thisMonth / 100)}</div>
        <div className="stat-desc">{thisMonthDate}</div>
      </div>

      <div className="stat">
        <div className="stat-title">This Quarter</div>
        <div className="stat-value">{formatter.format(thisQuarter / 100)}</div>
        <div className="stat-desc">
          Q{thisQuarterDate} - {thisYearDate}
        </div>
      </div>

      <div className="stat">
        <div className="stat-title">This Year</div>
        <div className="stat-value">{formatter.format(thisYear / 100)}</div>
        <div className="stat-desc">Year {thisYearDate}</div>
      </div>
    </div>
  );
}

const todayDate = moment().format("DD MMM, YYYY");

const thisWeekStartDate = moment().startOf("week").format("DD MMM");

const thisWeekEndDate = moment().endOf("week").format("DD MMM, YYYY");

const thisMonthDate = moment().format("MMMM, yyyy");

const thisQuarterDate = moment().quarter();

const thisYearDate = moment().year();

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  notation: "compact",
});
