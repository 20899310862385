import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CREATE_USER, UPDATE_USER } from "../../utils/mutations";
import { USERS, USER } from "../../utils/queries";
import { useMutation } from "@apollo/client";

export default function UserForm(props) {
  const { mode, info, id } = props;

  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();

  // create
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: () => {
      setLoader(false);
      toast.dismiss();
      toast.success("User created successfully!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [{ query: USERS }],
  });
  // update
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setLoader(false);
      toast.dismiss();
      toast.success("User updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: USERS },
      { query: USER, variables: { userId: id } },
    ],
  });

  // submit
  const onSubmit = (data) => {
    setLoader(true);
    const { name, email, role, status } = data.user;
    if (mode === "create") {
      createUser({
        variables: {
          inputs: {
            name,
            email,
            role,
          },
        },
      });
    } else if (mode === "update") {
      updateUser({
        variables: {
          inputs: {
            id: id,
            name,
            email,
            role,
            status,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (info) {
      setValue("user.name", info.name);
      setValue("user.email", info.email);
      setValue("user.role", info.role);
      setValue("user.status", info.status);
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Full name"
            className="input input-bordered w-full"
            required
            {...register("user.name")}
          />
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="user@email.com"
            className="input input-bordered w-full"
            required
            {...register("user.email")}
          />
        </div>
        {/* role */}
        <div>
          <label htmlFor="role" className="label">
            <span className="label-text font-semibold">Role</span>
          </label>
          <select
            defaultValue={"Select"}
            className="select select-bordered w-full"
            required
            {...register("user.role")}
          >
            <option disabled>Select</option>
            <option value="ADMIN">Admin</option>
            <option value="MANAGER">Manager</option>
          </select>
        </div>
        {/* status */}
        {mode === "update" && (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              defaultValue={"Select"}
              className="select select-bordered w-full"
              required
              {...register("user.status")}
            >
              <option disabled>Select</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>
        )}
        {/* submit */}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
