import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/Auth";
import { DASHBOARD } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import SalesStats from "../library/stats/Sales";
import OperationsStats from "../library/stats/Operations";
import VendorStats from "../library/stats/Vendors";
import SourceStats from "../library/stats/Sources";
import OrderStats from "../library/stats/Orders";

function Home(props) {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");
  const [isManager] = useState(user.data.role === "MANAGER");

  const { loading, data } = useQuery(DASHBOARD, {
    onCompleted: () => {
      toast.dismiss();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    pollInterval: 30000,
  });

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  return (
    <UserLayout>
      <HeadSection heading="Dashboard"></HeadSection>
      {data && (
        <section className="pt-8 space-y-8">
          {isAdmin && (
            <div>
              <h2 className="text-xl font-semibold p-2">Sales</h2>
              <SalesStats
                today={data?.dashboard?.salesToday}
                thisWeek={data?.dashboard?.salesThisWeek}
                thisMonth={data?.dashboard?.salesThisMonth}
                thisQuarter={data?.dashboard?.salesThisQuarter}
                thisYear={data?.dashboard?.salesThisYear}
              />
            </div>
          )}
          <div>
            <h2 className="text-xl font-semibold p-2">Operations</h2>
            <OperationsStats
              submitted={data?.dashboard?.ordersSubmittedThisWeek}
              fulfilled={data?.dashboard?.ordersFulfilledThisWeek}
              products={data?.dashboard?.availableProducts}
              customers={data?.dashboard?.totalCustomers}
            />
          </div>
          {isAdmin && (
            <div className="grid grid-flow-row md:grid-flow-col gap-6">
              <div className="">
                <h2 className="text-xl font-semibold p-2">
                  Top 5 Vendors
                  <span className="font-light"> (This month)</span>
                </h2>
                <VendorStats list={data?.dashboard?.topVendors} />
              </div>
              <div>
                <h2 className="text-xl font-semibold p-2">
                  Top 5 Sources
                  <span className="font-light"> (This month)</span>
                </h2>
                <SourceStats list={data?.dashboard?.topSources} />
              </div>
            </div>
          )}
          {isManager && (
            <div>
              <h2 className="text-xl font-semibold p-2">
                Orders to Fulfill
                <span className="font-light">
                  {" "}
                  (Total: {data?.dashboard?.ordersToBeFulfilled.length})
                </span>
              </h2>
              <OrderStats list={data?.dashboard?.ordersToBeFulfilled} />
            </div>
          )}
        </section>
      )}
    </UserLayout>
  );
}

export default Home;
