import { Link } from "react-router-dom";
import {
  UPDATE_DEFAULT_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
} from "../../utils/mutations";
import { CUSTOMER } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export default function AddressesList(props) {
  const { id, addresses } = props;

  const [updateDefaultCustomerAddress] = useMutation(
    UPDATE_DEFAULT_CUSTOMER_ADDRESS,
    {
      onCompleted: () => {
        toast.dismiss();
        toast.success("Default address updated successfully!");
      },
      onError: (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      refetchQueries: [{ query: CUSTOMER, variables: { customerId: id } }],
    }
  );

  const [deleteCustomerAddress] = useMutation(DELETE_CUSTOMER_ADDRESS, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Address deleted successfully!");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [{ query: CUSTOMER, variables: { customerId: id } }],
  });

  const handleDefault = (addressId) => {
    toast.loading("Updating default address...");
    updateDefaultCustomerAddress({
      variables: {
        inputs: {
          id: addressId,
          customer: id,
        },
      },
    });
  };

  const handleDelete = (addressId) => {
    toast.loading("Deleting address...");
    deleteCustomerAddress({
      variables: {
        addressId: addressId,
      },
    });
  };

  return (
    <div>
      <div>
        <ul className="grid grid-cols-2 lg:grid-cols-1 lg:divide-y-2">
          {addresses &&
            addresses.map((address, index) => (
              <li key={address.id} className="py-2 px-1">
                <div className="flex justify-between">
                  <div className="flex flex-col p-2">
                    <p className="pb-2">
                      <span className="text-sm font-semibold">
                        # {index + 1}: {address.type}
                      </span>
                    </p>
                    {address.company && (
                      <p>
                        <span className="font-semibold">Company: </span>
                        <span>{address.company}</span>
                      </p>
                    )}
                    {address.phone && (
                      <p>
                        <span className="font-semibold">Phone: </span>
                        <span>{address.phone}</span>
                      </p>
                    )}
                    {address.street && (
                      <p>
                        <span className="font-semibold">Street: </span>
                        <span>{address.street}</span>
                      </p>
                    )}
                    {address.additional && (
                      <p>
                        <span className="font-semibold">Additional: </span>
                        <span>{address.additional}</span>
                      </p>
                    )}
                    {address.suburb && (
                      <p>
                        <span className="font-semibold">Suburb: </span>
                        <span>{address.suburb}</span>
                        {address.postcode && <span>, {address.postcode}</span>}
                      </p>
                    )}
                    {address.state && (
                      <p>
                        <span className="font-semibold">State: </span>
                        <span>{address.state}</span>
                      </p>
                    )}
                    {address.country && (
                      <p>
                        <span className="font-semibold">Country: </span>
                        <span>{address.country}</span>
                      </p>
                    )}
                    {address.note && (
                      <p>
                        <span className="font-semibold">Note: </span>
                        <span>{address.note}</span>
                      </p>
                    )}
                    <div className="space-x-2 pt-2">
                      {!address.default && (
                        <button
                          className="btn btn-xs btn-outline"
                          onClick={() => handleDefault(address.id)}
                        >
                          Make default
                        </button>
                      )}
                      <Link
                        to={`/update/address/${address.id}?customer=${address.customer}`}
                        className="btn btn-xs btn-outline"
                      >
                        Update
                      </Link>
                      {!address.default && (
                        <button
                          className="btn btn-xs btn-outline"
                          onClick={() => handleDelete(address.id)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
