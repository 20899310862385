import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import ProfileForm from "../library/form/Profile";
import PasswordForm from "../library/form/Password";

function Profile(props) {
  return (
    <UserLayout title={`Profile`}>
      <HeadSection heading="Profile"></HeadSection>
      <section className="pt-8 space-y-8 max-w-md divide-y-2">
        <ProfileForm />
        <PasswordForm />
      </section>
    </UserLayout>
  );
}

export default Profile;
