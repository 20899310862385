import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { CHANGE_PASSWORD } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export default function PasswordForm(props) {
  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, formState, watch, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      new_password: "",
      password_confirmation: "",
    },
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { errors } = formState;

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Password updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { new_password } = data;
    changePassword({
      variables: {
        password: new_password,
      },
    });
  };

  useEffect(() => {
    setValue("new_password", "");
    setValue("password_confirmation", "");
  }, [setValue]);

  return (
    <div>
      <form>
        {/* password */}
        <div>
          <label htmlFor="password" className="label">
            <span className="label-text">Password</span>
          </label>
          <input
            type="password"
            name="new_password"
            placeholder="Password"
            {...register("new_password", {
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters",
              },
            })}
            className={`input input-bordered w-full ${
              errors.new_password ? "input-error" : ""
            }`}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-error mt-1">{message}</p>
            )}
          />
        </div>
        {/* password_confirmation */}
        <div>
          <label htmlFor="password_confirmation" className="label">
            <span className="label-text">Confirm Password</span>
          </label>
          <input
            type="password"
            name="password_confirmation"
            placeholder="Confirm Password"
            {...register("password_confirmation", {
              required: "Password confirmation is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters",
              },
              validate: (value) => {
                if (value === watch("new_password")) {
                  return true;
                }
                return "Passwords do not match";
              },
            })}
            className={`input input-bordered w-full ${
              errors.password_confirmation ? "input-error" : ""
            }`}
          />
          <ErrorMessage
            errors={errors}
            name="password_confirmation"
            render={({ message }) => (
              <p className="text-error mt-1">{message}</p>
            )}
          />
        </div>
        {/* submit */}
        <div className="mt-4">
          <button
            type="submit"
            className={`btn btn-primary ${loader ? "loading" : ""}`}
            onClick={handleSubmit(onSubmit)}
          >
            Change
          </button>
        </div>
      </form>
    </div>
  );
}
