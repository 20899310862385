import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  CREATE_VENDOR_SOURCE,
  UPDATE_VENDOR_SOURCE,
} from "../../utils/mutations";
import { VENDOR, SOURCE } from "../../utils/queries";
import { useMutation } from "@apollo/client";

export default function SourceForm(props) {
  const [loader, setLoader] = useState(false);
  const [vendorId, setVendorId] = useState(null);

  const { mode, info, id } = props;

  const { register, handleSubmit, setValue, reset } = useForm();

  const [searchParams] = useSearchParams();

  // create
  const [createVendorSource] = useMutation(CREATE_VENDOR_SOURCE, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer address created successfully!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: VENDOR, variables: { vendorId: vendorId } }],
  });
  // update
  const [updateVendorSource] = useMutation(UPDATE_VENDOR_SOURCE, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer address updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: SOURCE, variables: { sourceId: id } },
      { query: VENDOR, variables: { vendorId: vendorId } },
    ],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { name, url } = data.source;
    if (mode === "create") {
      createVendorSource({
        variables: {
          inputs: {
            id: id,
            name,
            url,
            vendorId: vendorId,
          },
        },
      });
    } else if (mode === "update") {
      updateVendorSource({
        variables: {
          inputs: {
            id: id,
            name,
            url,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (searchParams) setVendorId(searchParams.get("vendor"));
  }, [searchParams]);

  useEffect(() => {
    if (info) {
      setValue("source.name", info?.name);
      setValue("source.url", info?.url);
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="input input-bordered w-full"
            {...register("source.name")}
          />
        </div>
        {/* url */}
        <div>
          <label htmlFor="url" className="label">
            <span className="label-text font-semibold">URL</span>
          </label>
          <input
            type="text"
            name="url"
            id="url"
            className="input input-bordered w-full"
            {...register("source.url")}
          />
        </div>
        {/* submit */}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
