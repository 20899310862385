import { useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { ADD_ORDER_ITEM } from "../../utils/mutations";
import { FIND_PRODUCTS, ORDER } from "../../utils/queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";

export default function ItemForm(props) {
  const { orderId, customer } = props;

  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [description, setDescription] = useState(null);
  const [enteredPrice, setEnteredPrice] = useState(0);
  const [enteredQuantity, setEnteredQuantity] = useState(1);

  const [findProducts] = useLazyQuery(FIND_PRODUCTS, {
    onCompleted: (data) => {
      setProducts(data.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [addOrderItem] = useMutation(ADD_ORDER_ITEM, {
    onCompleted: () => {
      toast.success("Item added successfully");
      setSelectedProduct(null);
      setProductId(null);
      setEnteredPrice(0);
      setEnteredQuantity(1);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const handlePriceChange = (e) => {
    setEnteredPrice(parseInt(parseFloat(e.target.value).toFixed(2) * 100));
  };

  const handleQuantityChange = (e) => {
    setEnteredQuantity(parseInt(e.target.value));
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    if (selectedProduct) {
      addOrderItem({
        variables: {
          inputs: {
            orderId: orderId,
            product: productId,
            description: description,
            price: enteredPrice,
            quantity: enteredQuantity,
            customer: customer,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      const product = products.find(
        (product) => product.title === selectedProduct
      );
      setProductId(product?.id);
      setDescription(product?.title);
    }
  }, [selectedProduct, products]);

  return (
    <div className="grid md:grid-cols-5 gap-4 p-4">
      <div className="col-span-2">
        <label htmlFor="product" className="label">
          <span className="label-text font-semibold">Product</span>
        </label>
        <Combobox
          name="product"
          value={selectedProduct?.id}
          onChange={setSelectedProduct}
          nullable
        >
          <div className="relative">
            <Combobox.Input
              placeholder="Search for a product"
              className="input input-bordered w-full"
              onChange={(event) => {
                findProducts({
                  variables: { title: event.target.value },
                });
              }}
            />
            <Combobox.Options className="absolute mt-1 w-auto divide-y-2 border-solid border-2 bg-white">
              {products &&
                products.map((item) => (
                  <Combobox.Option
                    key={item.title}
                    value={item.title}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-4 ${
                        active ? "bg-accent text-white" : "text-gray-900"
                      }`
                    }
                  >
                    <div className="flex gap-4 content-center md:col-span-2">
                      <div>
                        <img
                          src={thumbnailUri(item.thumbnail)}
                          alt={item.title}
                          className="w-12 h-12"
                        />
                      </div>
                      <div>
                        <span className="font-bold">{item.title}</span>
                        <br />
                        <span>{item.sku}</span>
                      </div>
                    </div>
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
      <div>
        <label htmlFor="price" className="label">
          <span className="label-text font-semibold">Price</span>
        </label>
        <input
          type="number"
          min="0"
          step="0.01"
          name={`price`}
          className="input input-bordered w-full"
          onChange={handlePriceChange}
        />
      </div>
      <div>
        <label htmlFor="product" className="label">
          <span className="label-text font-semibold">Qty</span>
        </label>
        <input
          type="number"
          min="1"
          max="100"
          step="1"
          name={`qty`}
          className="input input-bordered w-full"
          value={enteredQuantity}
          onChange={handleQuantityChange}
        />
      </div>
      <div className="flex items-end">
        <button className="btn btn-outline" onClick={handleAddItem}>
          Add
        </button>
      </div>
    </div>
  );
}

const thumbnailUri = (thumbnail) => {
  if (thumbnail)
    return `https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/${thumbnail}`;
  return "https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/placeholder-square.jpg";
};
