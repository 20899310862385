import { useState, useEffect } from "react";
import { CUSTOMERS } from "../../utils/queries";
import { DELETE_CUSTOMER } from "../../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export default function CustomersTable(props) {
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(resultsPerPage);

  const { loading } = useQuery(CUSTOMERS, {
    onCompleted: (data) => {
      toast.dismiss();
      if (data?.customers) {
        setResults(data.customers);
        setResponse(data.customers);
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    onCompleted: () => {
      toast.success("Customer deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: CUSTOMERS }],
  });

  const sliceData = (data, page, rows) => {
    setFrom(data.length > 0 ? (page - 1) * rows + 1 : 0);
    setTo(page * rows > data.length ? data.length : page * rows);
    return data.slice((page - 1) * rows, page * rows);
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;
    if (keyword.length > 0) {
      const filteredData = response.filter((item) => {
        return (
          item.name.toLowerCase().includes(keyword.toLowerCase()) ||
          item.email?.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      setResults(filteredData);
      setCurrentPage(1);
    } else {
      setResults(response);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the customer?")) {
      deleteCustomer({
        variables: {
          customerId: id,
        },
      });
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  useEffect(() => {
    if (results) setTotalPages(Math.ceil(results.length / resultsPerPage));
  }, [results, resultsPerPage]);

  useEffect(() => {
    const slice = sliceData(results, currentPage, resultsPerPage);
    setList([...slice]);
  }, [results, currentPage, setList, resultsPerPage]);

  return (
    <div className="space-y-6">
      {/* table filter */}
      <div className="flex justify-start w-full px-2">
        <input
          className="input input-bordered w-full max-w-md bg-white ring-primary-focus form-control"
          type="search"
          placeholder="Filter by Email / Name"
          onChange={handleSearch}
        />
      </div>
      <div className="overflow-x-auto rounded border-solid border-2">
        <table className="table-normal divide-y-2 bg-white rounded w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th className="text-center">#</th>
              <th>Name</th>
              <th>Address</th>
              <th className="text-center">Country</th>
              <th className="text-center">Orders</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list &&
              list.map((value, index) => (
                <tr key={value.id}>
                  <th>{from + index}</th>
                  <td>
                    <Link to={`/customer/${value.id}`}>
                      <span className="font-bold underline">{value.name}</span>
                      <br />
                      <span className="underline">{value.email}</span>
                    </Link>
                  </td>
                  <td>
                    <span>
                      {value.address.street}{" "}
                      {value.address.addtional
                        ? `, ${value.address.addtional}`
                        : ``}
                    </span>
                    <br />
                    <span>
                      {value.address.suburb}, {value.address.state}{" "}
                    </span>
                  </td>
                  <td className="text-center">{value.address.country}</td>
                  <td className="text-center">{value.transactions}</td>
                  <td>
                    <Link
                      to={`/update/customer/${value.id}`}
                      className="btn btn-xs btn-ghost"
                    >
                      Update
                    </Link>
                    <button
                      className="btn btn-xs btn-ghost"
                      onClick={() => handleDelete(value.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* table pagination */}
      <div className="flex w-full px-2">
        {results && (
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{from}</span> -{" "}
                <span className="font-bold">{to}</span> of{" "}
                <span className="font-bold">{results.length}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === 1 || totalPages === 0}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
