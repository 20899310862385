import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import OrdersTable from "../library/table/Orders";

function Orders(props) {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");

  return (
    <UserLayout title={`Orders`}>
      <HeadSection heading="Orders">
        <Link to={"/create/order"} className="btn btn-primary">
          New Order
        </Link>
      </HeadSection>
      <section className="pt-8 space-y-8">
        <OrdersTable admin={isAdmin} />
      </section>
    </UserLayout>
  );
}

export default Orders;
