import { Link } from "react-router-dom";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import VendorsTable from "../library/table/Vendors";

function Vendors(props) {
  return (
    <UserLayout title={`Vendors`}>
      <HeadSection heading="Vendors">
        <Link to={"/create/vendor"} className="btn btn-primary">
          New Vendor
        </Link>
      </HeadSection>
      <section className="pt-8 space-y-8">
        <VendorsTable />
      </section>
    </UserLayout>
  );
}

export default Vendors;
