import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/Auth";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ORDER, ORDERS } from "../utils/queries";
import {
  SUBMIT_ORDER,
  DELETE_ORDER,
  CANCEL_ORDER,
  FULFILL_ORDER,
  RETURN_ORDER,
  REVERT_ORDER,
} from "../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import toast from "react-hot-toast";
import AssociateForm from "../library/form/Associate";
import CustomerInfo from "../library/info/Customer";
import AddressInfo from "../library/info/Address";
import ItemForm from "../library/form/Item";
import ItemsList from "../library/list/Items";
import OrderInfo from "../library/info/Order";
import InvoiceForm from "../library/form/Invoice";
import NoteForm from "../library/form/Note";
import TrackingForm from "../library/form/Tracking";
import { generateSlip } from "../utils/generateSlip";

function Order(props) {
  const { id } = useParams();

  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");

  const [canDraft, setCanDraft] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [canFulfill, setCanFulfill] = useState(false);
  const [canReturn, setCanReturn] = useState(false);
  const [canInvoice, setCanInvoice] = useState(false);

  const navigate = useNavigate();

  const { loading, data } = useQuery(ORDER, {
    variables: { orderId: id },
    onCompleted: () => {
      toast.dismiss();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [submitOrder] = useMutation(SUBMIT_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order submitted successfully!");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: null } },
    ],
  });

  const [deleteOrder] = useMutation(DELETE_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order deleted successfully!");
      navigate("/orders");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDERS, variables: { status: null, vendor: null } },
    ],
  });

  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order cancelled successfully!");
      navigate("/orders");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: null } },
    ],
  });

  const [fulfillOrder] = useMutation(FULFILL_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order fulfilled successfully!");
      navigate("/orders");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: null } },
    ],
  });

  const [returnOrder] = useMutation(RETURN_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order returned successfully!");
      navigate("/orders");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: null } },
    ],
  });

  const [revertOrder] = useMutation(REVERT_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order reverted successfully!");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: null } },
    ],
  });

  const handleOrderSubmit = () => {
    toast.loading("Submitting order...");
    submitOrder({
      variables: {
        orderId: id,
      },
    });
  };

  const handleOrderDelete = () => {
    toast.loading("Deleting order...");
    window.confirm("Are you sure you want to delete this order?") &&
      deleteOrder({
        variables: {
          orderId: id,
        },
      });
  };

  const handleOrderCancel = () => {
    toast.loading("Cancelling order...");
    window.confirm("Are you sure you want to cancel this order?") &&
      cancelOrder({
        variables: {
          orderId: id,
        },
      });
  };

  const handleOrderFulfill = () => {
    toast.loading("Fulfilling order...");
    fulfillOrder({
      variables: {
        orderId: id,
      },
    });
  };

  const handleOrderReturn = () => {
    toast.loading("Returning order...");
    window.confirm("Are you sure you want to return this order?") &&
      returnOrder({
        variables: {
          orderId: id,
        },
      });
  };

  const handleOrderRevert = () => {
    toast.loading("Reverting order...");
    window.confirm("Are you sure you want to revert this order?") &&
      revertOrder({
        variables: {
          orderId: id,
        },
      });
  };

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  useEffect(() => {
    const status = data?.order?.status;

    if (data?.order) {
      const customer = data?.order?.customer?.id !== null;
      const billing = data?.order?.billing?.id !== null;
      const shipping = data?.order?.shipping?.id !== null;
      const items = data?.order?.items?.length > 0;
      const total = data?.order?.total > 0;
      const code = data?.order?.tracking.code !== null;
      const carrier = data?.order?.tracking.carrier !== null;

      setCanDraft(data.order.status === "DRAFT");

      setCanSubmit(
        status === "DRAFT" && customer && billing && shipping && items && total
      );

      setCanFulfill(status === "SUBMITTED" && code && carrier);

      setCanReturn(status === "FULFILLED");

      setCanInvoice(status !== "DRAFT" && status !== "CANCELLED");
    }
  }, [data]);

  return (
    <UserLayout title={`Order Information`}>
      <HeadSection heading={`Order Information`}>
        {/* create order */}
        {data?.order?.status === "SUBMITTED" && (
          <Link to={"/create/order"} className="btn btn-primary">
            New Order
          </Link>
        )}
        {/* delete */}
        {canDraft && data?.order?.number === null && (
          <button
            className="btn btn-outline btn-error"
            onClick={() => handleOrderDelete()}
          >
            Delete
          </button>
        )}
        {/* submit */}
        {canSubmit && (
          <button
            className="btn btn-primary"
            onClick={() => handleOrderSubmit()}
          >
            Submit
          </button>
        )}
        {/* revert */}
        {data?.order?.status === "SUBMITTED" && (
          <button
            className="btn btn-outline"
            onClick={() => handleOrderRevert()}
          >
            Revert
          </button>
        )}
        {/* cancel */}
        {data?.order?.status === "SUBMITTED" && (
          <button
            className="btn btn-outline btn-error"
            onClick={() => handleOrderCancel()}
          >
            Cancel
          </button>
        )}
        {/* generate slip */}
        {data?.order?.status === "SUBMITTED" && (
          <button
            className="btn btn-outline"
            onClick={() => generateSlip(data)}
          >
            Print Slip
          </button>
        )}
        {data?.order?.status === "FULFILLED" && (
          <button
            className="btn btn-outline"
            onClick={() => generateSlip(data)}
          >
            Print Slip
          </button>
        )}
        {/* fulfill */}
        {canFulfill && (
          <button
            className="btn btn-primary"
            onClick={() => handleOrderFulfill()}
          >
            Fulfill
          </button>
        )}
        {/* return */}
        {canReturn && (
          <button
            className="btn btn-outline"
            onClick={() => handleOrderReturn()}
          >
            Return
          </button>
        )}
        <Link to={`/orders`} className="btn btn-outline">
          Back
        </Link>
      </HeadSection>
      {data && (
        <section className="pt-8 space-y-8">
          <div className="grid grid-flow-row lg:grid-flow-col gap-6">
            <section className="col-span-1 order-2 lg:order-1 max-w-md break-all">
              <div className="grid grid-flow-row gap-6">
                <div>
                  <h2 className="text-xl font-semibold p-2">Customer</h2>

                  {data?.order?.customer?.email ? (
                    <CustomerInfo
                      name={data?.order.customer?.name}
                      email={data?.order.customer?.email}
                    />
                  ) : (
                    <div className="p-2 space-y-4">
                      <AssociateForm type="customer" orderId={id} />
                      <Link
                        to={`/create/customer?order=${id}`}
                        className="btn btn-xs btn-outline"
                      >
                        Add new
                      </Link>
                    </div>
                  )}
                </div>
                <div>
                  <h2 className="text-xl font-semibold p-2">Addresses</h2>
                  <div className="p-2 space-y-4">
                    {data?.order?.customer?.id === null && (
                      <p className="text-gray-700 text-sm">
                        Customer not selected
                      </p>
                    )}
                    {data?.order?.customer?.id && (
                      <Link
                        to={`/create/address/?customer=${data.order.customer.id}`}
                        className="btn btn-xs btn-outline"
                      >
                        Add new
                      </Link>
                    )}
                    {data?.order?.customer?.id && canDraft && (
                      <AssociateForm
                        type="shipping"
                        orderId={id}
                        customerId={data.order.customer.id}
                      />
                    )}
                    {data?.order?.shipping?.id !== null && (
                      <AddressInfo
                        address={data.order.shipping}
                        customerId={data.order.customer.id}
                        type="Shipping"
                      />
                    )}
                    {data?.order?.customer?.id && canDraft && (
                      <AssociateForm
                        type="billing"
                        orderId={id}
                        customerId={data.order.customer.id}
                      />
                    )}
                    {data?.order?.billing?.id !== null && (
                      <AddressInfo
                        address={data.order.billing}
                        customerId={data.order.customer.id}
                        type="Billing"
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="col-span-1 lg:col-span-5 order-3 lg:order-2">
              {isAdmin && canInvoice && (
                <>
                  <h2 className="text-xl font-semibold p-2">Invoice</h2>
                  <InvoiceForm orderId={id} invoice={data?.order?.invoice} />
                </>
              )}
              <h2 className="text-xl font-semibold p-2">Items</h2>
              {canDraft && (
                <ItemForm orderId={id} customer={data?.order?.customer.id} />
              )}
              <ItemsList
                orderId={id}
                items={data?.order?.items}
                mode={canDraft ? "edit" : "view"}
              />
              {data?.order?.status === "SUBMITTED" && (
                <>
                  <h2 className="text-xl font-semibold p-2">Tracking</h2>
                  <TrackingForm
                    orderId={id}
                    code={data?.order?.tracking.code}
                    carrier={data?.order?.tracking.carrier}
                    cost={data?.order?.tracking.cost}
                    disabled={false}
                  />
                </>
              )}
              {data?.order?.status === "FULFILLED" && (
                <>
                  <h2 className="text-xl font-semibold p-2">Tracking</h2>
                  <TrackingForm
                    orderId={id}
                    code={data?.order?.tracking.code}
                    carrier={data?.order?.tracking.carrier}
                    cost={data?.order?.tracking.cost}
                    disabled={true}
                  />
                </>
              )}
              <h2 className="text-xl font-semibold p-2">Note</h2>
              <NoteForm orderId={id} note={data?.order?.note} />
            </section>
            <section className="col-span-1 order-1 lg:order-3">
              <div className="grid grid-flow-row gap-6">
                <div>
                  <h2 className="text-xl font-semibold p-2">Information</h2>
                  <OrderInfo order={data?.order} />
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
    </UserLayout>
  );
}

export default Order;
