import "./App.css";
import { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/Auth";
import Login from "./page/Login";
import Reset from "./page/Reset";
import Error from "./page/Error";
import Home from "./page/Home";
import Profile from "./page/Profile";
import Orders from "./page/Orders";
import Order from "./page/Order";
import Users from "./page/Users";
import Products from "./page/Products";
import Vendors from "./page/Vendors";
import Vendor from "./page/Vendor";
import Customers from "./page/Customers";
import Customer from "./page/Customer";
import Create from "./page/Create";
import Update from "./page/Update";
import { useState } from "react";

function App() {
  const { user } = useContext(AuthContext);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (user?.data?.role) setUserRole(user.data.role);
  }, [user]);

  return (
    <Routes>
      {/* open routes */}
      <Route path="*" element={<Error />} />
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home role={userRole} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/products"
        element={
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order/:id"
        element={
          <ProtectedRoute>
            <Order role={userRole} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create/:type"
        element={
          <ProtectedRoute>
            <Create />
          </ProtectedRoute>
        }
      />
      <Route
        path="/update/:type/:id"
        element={
          <ProtectedRoute>
            <Update />
          </ProtectedRoute>
        }
      />
      {/* admin routes */}
      <Route
        path="/users"
        element={
          <AdminRoute>
            <Users />
          </AdminRoute>
        }
      />
      <Route
        path="/vendors"
        element={
          <AdminRoute>
            <Vendors />
          </AdminRoute>
        }
      />
      <Route
        path="/vendor/:id"
        element={
          <AdminRoute>
            <Vendor />
          </AdminRoute>
        }
      />
      <Route
        path="/customers"
        element={
          <AdminRoute>
            <Customers />
          </AdminRoute>
        }
      />
      <Route
        path="/customer/:id"
        element={
          <AdminRoute>
            <Customer />
          </AdminRoute>
        }
      />
    </Routes>
  );
}

export default App;

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // user not logged in
  return <>{!user ? props.children : <Navigate to="/" />}</>;
}

function ProtectedRoute(props) {
  const { user } = useContext(AuthContext);
  // admin or manager user
  return (
    <>
      {user &&
      (user.data?.role === "ADMIN" || user.data?.role === "MANAGER") ? (
        props.children
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

function AdminRoute(props) {
  const { user } = useContext(AuthContext);
  // admin user
  return (
    <>
      {user && user.data?.role === "ADMIN" ? (
        props.children
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}
