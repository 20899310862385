import moment from "moment";

export default function OrderInfo(props) {
  const { status, vendor, date, source, number, ref, total } = props.order;

  return (
    <div>
      <div>
        <div className="divide-y-2">
          {/* status */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Status:</span>
              <br />
              <span
                className={`text-xs py-1 px-2 text-white ${statusTagColor(
                  status
                )}`}
              >
                {status}
              </span>
            </p>
          </div>
          {/* number */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Number:</span>
              <br /> <span>{number ? number : "-"}</span>
            </p>
          </div>
          {/* date */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Date:</span>
              <br /> <span>{moment(date / 1000, "X").format("LL")}</span>
            </p>
          </div>
          {/* ref */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Ref:</span>
              <br /> <span>{ref}</span>
            </p>
          </div>
          {/* vendor */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Vendor:</span>
              <br /> <span>{vendor.dealer}</span>
            </p>
          </div>
          {/* email */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Email:</span>
              <br /> <span>{vendor.email}</span>
            </p>
          </div>
          {/* source */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Source:</span>
              <br /> <span>{source.name}</span>
            </p>
          </div>
          {/* total */}
          <div className="p-2">
            <p className="text-lg">
              <span className="font-semibold">Total:</span>
              <br /> <span>{formatter.format(total / 100)}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function statusTagColor(str) {
  if (str === "DRAFT") return `bg-warning`;
  if (str === "SUBMITTED") return `bg-info`;
  if (str === "FULFILLED") return `bg-success`;
  if (str === "RETURNED") return `bg-error`;
  return `bg-base-300`;
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});
