import { Link } from "react-router-dom";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import ProductsGrid from "../library/grid/Products";

function Products(props) {
  return (
    <UserLayout title={`Products`}>
      <HeadSection heading="Products">
        <Link to={"/create/product"} className="btn btn-primary">
          New Product
        </Link>
      </HeadSection>
      <section className="pt-8 space-y-8">
        <ProductsGrid />
      </section>
    </UserLayout>
  );
}

export default Products;
