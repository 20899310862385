import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  CREATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_ADDRESS,
} from "../../utils/mutations";
import { CUSTOMER, ADDRESS } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import { Countries } from "../../utils/constants";

export default function AddressForm(props) {
  const [loader, setLoader] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const { mode, info, id } = props;

  const { register, handleSubmit, setValue, reset } = useForm();

  const [searchParams] = useSearchParams();

  // create
  const [createCustomerAddress] = useMutation(CREATE_CUSTOMER_ADDRESS, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer address created successfully!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: CUSTOMER, variables: { customerId: customerId } },
    ],
  });
  // update
  const [updateCustomerAddress] = useMutation(UPDATE_CUSTOMER_ADDRESS, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Customer address updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ADDRESS, variables: { addressId: id } },
      { query: CUSTOMER, variables: { customerId: customerId } },
    ],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const {
      type,
      phone,
      company,
      street,
      additional,
      suburb,
      state,
      postcode,
      country,
      note,
    } = data.address;
    if (mode === "create") {
      createCustomerAddress({
        variables: {
          inputs: {
            id: id,
            type,
            phone,
            company,
            street,
            additional,
            suburb,
            state,
            postcode,
            country,
            note,
            customer: customerId,
          },
        },
      });
    } else if (mode === "update") {
      updateCustomerAddress({
        variables: {
          inputs: {
            id: id,
            type,
            phone,
            company,
            street,
            additional,
            suburb,
            state,
            postcode,
            country,
            note,
            customer: customerId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (searchParams) setCustomerId(searchParams.get("customer"));
  }, [searchParams]);

  useEffect(() => {
    if (info) {
      setValue("address.type", info?.type);
      setValue("address.phone", info?.phone);
      setValue("address.company", info?.company);
      setValue("address.street", info?.street);
      setValue("address.additional", info?.additional);
      setValue("address.suburb", info?.suburb);
      setValue("address.state", info?.state);
      setValue("address.postcode", info?.postcode);
      setValue("address.country", info?.country);
      setValue("address.note", info?.note);
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* type */}
        <div>
          <label htmlFor="type" className="label">
            <span className="label-text font-semibold">Type</span>
          </label>
          <select
            name="type"
            id="type"
            className="input input-bordered w-full"
            required
            {...register("address.type")}
          >
            <option value="RESIDENTIAL">Residential</option>
            <option value="COMMERCIAL">Commercial</option>
          </select>
        </div>
        {/* phone */}
        <div>
          <label htmlFor="phone" className="label">
            <span className="label-text font-semibold">Phone</span>
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            className="input input-bordered w-full"
            required
            {...register("address.phone")}
          />
        </div>
        {/* company */}
        <div>
          <label htmlFor="company" className="label">
            <span className="label-text font-semibold">Company</span>
          </label>
          <input
            type="text"
            name="company"
            id="company"
            className="input input-bordered w-full"
            {...register("address.company")}
          />
        </div>
        {/* street */}
        <div>
          <label htmlFor="street" className="label">
            <span className="label-text font-semibold">Street</span>
          </label>
          <input
            type="text"
            name="street"
            id="street"
            className="input input-bordered w-full"
            required
            {...register("address.street")}
          />
        </div>
        {/* addtional */}
        <div>
          <label htmlFor="additional" className="label">
            <span className="label-text font-semibold">Additional</span>
          </label>
          <input
            type="text"
            name="additional"
            id="additional"
            className="input input-bordered w-full"
            {...register("address.additional")}
          />
        </div>
        {/* suburb */}
        <div>
          <label htmlFor="suburb" className="label">
            <span className="label-text font-semibold">Suburb</span>
          </label>
          <input
            type="text"
            name="suburb"
            id="suburb"
            className="input input-bordered w-full"
            required
            {...register("address.suburb")}
          />
        </div>
        {/* state */}
        <div>
          <label htmlFor="state" className="label">
            <span className="label-text font-semibold">State</span>
          </label>
          <select
            name="type"
            id="type"
            className="input input-bordered w-full"
            required
            {...register("address.state")}
          >
            <option value="NSW">NSW</option>
            <option value="VIC">VIC</option>
            <option value="QLD">QLD</option>
            <option value="WA">WA</option>
            <option value="SA">SA</option>
            <option value="TAS">TAS</option>
            <option value="ACT">ACT</option>
            <option value="NT">NT</option>
          </select>
        </div>
        {/* postcode */}
        <div>
          <label htmlFor="postcode" className="label">
            <span className="label-text font-semibold">Postcode</span>
          </label>
          <input
            type="text"
            name="postcode"
            id="postcode"
            className="input input-bordered w-full"
            required
            {...register("address.postcode")}
          />
        </div>
        {/* country */}
        <div>
          <label htmlFor="country" className="label">
            <span className="label-text font-semibold">Country</span>
          </label>
          <select
            name="country"
            id="country"
            defaultValue={"AU"}
            className="input input-bordered w-full"
            required
            {...register("address.country")}
          >
            {Countries &&
              Countries.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.label}
                </option>
              ))}
          </select>
        </div>
        {/* note */}
        <div>
          <label htmlFor="note" className="label">
            <span className="label-text font-semibold">Note</span>
          </label>
          <textarea
            name="note"
            id="note"
            className="input input-bordered w-full"
            {...register("address.note")}
          />
        </div>
        {/* submit */}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
