import { useState, useEffect } from "react";
import { PRODUCTS } from "../../utils/queries";
import { DELETE_PRODUCT } from "../../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { PhotoIcon } from "@heroicons/react/24/outline";

export default function ProductsGrid(props) {
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState(null);
  const [keyword, setKeyword] = useState(null);

  const { loading } = useQuery(PRODUCTS, {
    onCompleted: (data) => {
      toast.dismiss();
      if (data?.products) {
        setResults(data.products);
        setResponse(data.products);
        setStatuses([...new Set(data.products.map((p) => p.status))]);
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      toast.success("Product deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: PRODUCTS }],
  });

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the product?")) {
      deleteProduct({
        variables: {
          productId: id,
        },
      });
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    if (term.length > 0) {
      setKeyword(term);
    } else {
      setKeyword(null);
    }
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    if (value !== "ALL") {
      setStatus(value);
    } else {
      setStatus(null);
    }
  };

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  useEffect(() => {
    let filtered = response;
    if (keyword || status) {
      if (keyword) {
        filtered = filtered.filter((p) => {
          return p.title.toLowerCase().includes(keyword.toLowerCase());
        });
      }
      if (status) {
        filtered = filtered.filter((p) => p.status === status);
      }
      setResults(filtered);
    } else {
      setResults(response);
    }
  }, [status, keyword, response]);

  return (
    <div className="space-y-6">
      {/* table filter */}
      {response && (
        <div className="grid grid-flow-row xl:grid-flow-col px-2 gap-4">
          {/* search */}
          <div className="col-span-1">
            <input
              name="keyword"
              className="col-span-1 input input-bordered w-full max-w-md bg-white ring-primary-focus form-control"
              type="search"
              placeholder="Filter by Title / SKU"
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-span-1 flex flex-row justify-end gap-4">
            {/* status */}
            <select
              name="status"
              className="select select-bordered w-auto md:w-64"
              onChange={handleFilterChange}
            >
              <option value="ALL">All Statuses</option>
              {statuses.length > 0 &&
                statuses.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {results.length > 0 &&
          results.map((value, index) => (
            <li
              key={value.id}
              className="col-span-1 flex flex-col border-solid border-2"
            >
              <div className="relative">
                <p className="absolute left-2 top-2 bg-white py-1 px-2">
                  {index + 1}
                </p>
                <Link
                  className="absolute right-2 top-2 bg-white p-2"
                  to={`/update/thumbnail/${value.id}`}
                >
                  <PhotoIcon
                    className="h-6 w-6 text-primary"
                    aria-hidden="true"
                  />
                </Link>
                <img
                  className="w-full h-full"
                  src={thumbnailUri(value.thumbnail)}
                  alt={value.title}
                />
              </div>
              <div className="p-4">
                <div className="">
                  <p>
                    <span className="text-xs">{value.sku}</span>
                  </p>
                  <h2 className="text-xl font-semibold pb-1">{value.title}</h2>
                  <p className="text-xs">
                    <span>{value.package.weight / 1000} kg</span> |{" "}
                    <span>
                      {value.package.height / 10}cm x {value.package.width / 10}
                      cm x {value.package.depth / 10}cm
                    </span>
                  </p>
                </div>
                <div className="flex flex-row justify-between pt-4">
                  <span
                    className={`text-xs py-1 px-2 text-white ${statusTagColor(
                      value.status
                    )}`}
                  >
                    {value.status}
                  </span>
                  <span>
                    <Link
                      to={`/update/product/${value.id}`}
                      className="btn btn-xs btn-ghost"
                    >
                      Update
                    </Link>
                    <button
                      className="btn btn-xs btn-ghost"
                      onClick={() => handleDelete(value.id)}
                    >
                      Remove
                    </button>
                  </span>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

function statusTagColor(str) {
  if (str === "AVAILABLE") return `bg-info`;
  if (str === "OUT OF STOCK") return `bg-warning`;
  return `bg-base-300`;
}

const thumbnailUri = (thumbnail) => {
  if (thumbnail)
    return `https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/${thumbnail}`;
  return "https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/placeholder-square.jpg";
};
