import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const generateSlip = (data) => {
  const {
    number,
    ref,
    date,
    customer,
    items,
    billing,
    shipping,
    vendor,
    source,
  } = data.order;

  const thankYou = "Thank you for your order!";
  const orderDate = moment(date / 1000, "X").format("LL");

  const itemsTable = items.map((item, index) => {
    const { description, product, quantity } = item;
    return [index + 1, description, product.sku, `${quantity}`];
  });

  const doc = new jsPDF();

  // header
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text(`#${number}`, 20, 20);
  doc.text(`Ref.: ${ref}`, 20, 28);
  doc.text(`Date: ${orderDate}`, 20, 34);
  // addresses
  // shipping
  doc.setFontSize(8);
  doc.text(`SHIP TO`, 20, 50);
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`${customer.name}`, 20, 58);
  doc.text(`${shipping.street}`, 20, 64);
  if (shipping.additional) {
    doc.text(`${shipping.additional}`, 20, 70);
    doc.text(
      `${shipping.suburb}, ${shipping.state} ${shipping.postcode}`,
      20,
      76
    );
    doc.text(`${shipping.country}`, 20, 82);
  } else {
    doc.text(
      `${shipping.suburb}, ${shipping.state} ${shipping.postcode}`,
      20,
      70
    );
    doc.text(`${shipping.country}`, 20, 76);
  }
  // billing
  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  doc.text(`BILL TO`, 100, 50);
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`${customer.name}`, 100, 58);
  doc.text(`${billing.street}`, 100, 64);
  if (billing.additional) {
    doc.text(`${billing.additional}`, 100, 70);
    doc.text(
      `${billing.suburb}, ${billing.state} ${billing.postcode}`,
      100,
      76
    );
    doc.text(`${billing.country}`, 100, 82);
  } else {
    doc.text(
      `${billing.suburb}, ${billing.state} ${billing.postcode}`,
      100,
      70
    );
    doc.text(`${billing.country}`, 100, 76);
  }
  // items
  doc.autoTable({
    margin: { left: 20 },
    startY: 96,
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    },
    columnStyles: {
      0: { halign: "center" },
      2: { halign: "center" },
      3: { halign: "center" },
    },
    head: [["#", "Product", "SKU", "Quanity"]],
    body: itemsTable,
    allSectionHooks: true,
    didParseCell: (hookData) => {
      if (hookData.section === "head") {
        if (
          hookData.column.index === 0 ||
          hookData.column.index === 2 ||
          hookData.column.index === 3
        ) {
          hookData.cell.styles.halign = "center";
        }
      }
    },
    theme: "grid",
  });
  // footer
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(thankYou, 105, doc.lastAutoTable.finalY + 18, null, null, "center");
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(
    vendor.dealer,
    105,
    doc.lastAutoTable.finalY + 26,
    null,
    null,
    "center"
  );
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(
    vendor.address,
    105,
    doc.lastAutoTable.finalY + 34,
    null,
    null,
    "center"
  );
  doc.text(
    `ABN: ${vendor.abn}`,
    105,
    doc.lastAutoTable.finalY + 40,
    null,
    null,
    "center"
  );
  doc.text(
    `URL: ${source.url}`,
    105,
    doc.lastAutoTable.finalY + 46,
    null,
    null,
    "center"
  );

  // doc.save(`packaging-slip-${number}.pdf`);
  doc.autoPrint();
  //This is a key for printing
  doc.output("dataurlnewwindow");
};
