export default function OperationsStats(props) {
  const { submitted, fulfilled, products, customers } = props;

  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow w-full border-solid border-2">
      <div className="stat">
        <div className="stat-title">Order Submitted</div>
        <div className="stat-value">{nFormatter(submitted)}</div>
        <div className="stat-desc">This week</div>
      </div>

      <div className="stat">
        <div className="stat-title">Orders Fulfilled</div>
        <div className="stat-value">{nFormatter(fulfilled)}</div>
        <div className="stat-desc">This week</div>
      </div>

      <div className="stat">
        <div className="stat-title">Available Products</div>
        <div className="stat-value">{nFormatter(products)}</div>
        <div className="stat-desc">Currently</div>
      </div>

      <div className="stat">
        <div className="stat-title">Total Customers</div>
        <div className="stat-value">{nFormatter(customers)}</div>
        <div className="stat-desc">Overall</div>
      </div>
    </div>
  );
}

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
