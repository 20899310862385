import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CREATE_VENDOR, UPDATE_VENDOR } from "../../utils/mutations";
import { VENDORS, VENDOR } from "../../utils/queries";
import { useMutation } from "@apollo/client";

export default function VendorFrom(props) {
  const [loader, setLoader] = useState(false);

  const { mode, info, id } = props;

  const { register, handleSubmit, setValue, reset } = useForm();

  // create
  const [createVendor] = useMutation(CREATE_VENDOR, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Vendor created successfully!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: VENDORS }],
  });
  // update
  const [updateVendor] = useMutation(UPDATE_VENDOR, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Vendor updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: VENDORS },
      { query: VENDOR, variables: { vendorId: id } },
    ],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const {
      name,
      email,
      dealer,
      tier,
      entity,
      abn,
      address,
      url,
      owned,
      status,
    } = data.vendor;
    if (mode === "create") {
      createVendor({
        variables: {
          inputs: {
            name,
            email,
            dealer,
            tier: parseInt(tier),
            entity,
            abn,
            address,
            url,
            owned: owned === "YES",
          },
        },
      });
    } else if (mode === "update") {
      updateVendor({
        variables: {
          inputs: {
            id: id,
            name,
            email,
            dealer,
            tier: parseInt(tier),
            entity,
            abn,
            address,
            url,
            owned: owned === "YES",
            status,
          },
        },
      });
    }
  };

  const getSourceUrl = (sources) => {
    const url = sources.find((source) => source.default === true)?.url ?? "";
    return url;
  };

  useEffect(() => {
    if (info) {
      setValue("vendor.name", info.name);
      setValue("vendor.email", info.email);
      setValue("vendor.dealer", info.dealer);
      setValue("vendor.tier", info.tier);
      setValue("vendor.entity", info.entity);
      setValue("vendor.abn", info.abn);
      setValue("vendor.address", info.address);
      setValue("vendor.owned", info.owned ? "YES" : "NO");
      setValue("vendor.status", info.status);
      if (info?.sources.length > 0) {
        setValue("vendor.url", getSourceUrl(info.sources));
      }
    }
  }, [info, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="input input-bordered w-full"
            required
            {...register("vendor.name")}
          />
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="input input-bordered w-full"
            {...register("vendor.email")}
          />
        </div>
        {/* dealer */}
        <div>
          <label htmlFor="dealer" className="label">
            <span className="label-text font-semibold">Dealer</span>
          </label>
          <input
            type="text"
            name="dealer"
            id="dealer"
            className="input input-bordered w-full"
            {...register("vendor.dealer")}
          />
        </div>
        {/* tier */}
        <div>
          <label htmlFor="tier" className="label">
            <span className="label-text font-semibold">Tier</span>
          </label>
          <input
            type="number"
            name="tier"
            id="tier"
            max={3}
            min={1}
            className="input input-bordered w-full"
            {...register("vendor.tier")}
          />
        </div>
        {/* entity */}
        <div>
          <label htmlFor="entity" className="label">
            <span className="label-text font-semibold">Entity</span>
          </label>
          <input
            type="text"
            name="entity"
            id="entity"
            className="input input-bordered w-full"
            {...register("vendor.entity")}
          />
        </div>
        {/* abn */}
        <div>
          <label htmlFor="abn" className="label">
            <span className="label-text font-semibold">ABN</span>
          </label>
          <input
            type="text"
            name="abn"
            id="abn"
            className="input input-bordered w-full"
            {...register("vendor.abn")}
          />
        </div>
        {/* address */}
        <div>
          <label htmlFor="address" className="label">
            <span className="label-text font-semibold">Address</span>
          </label>
          <input
            type="text"
            name="address"
            id="address"
            className="input input-bordered w-full"
            {...register("vendor.address")}
          />
        </div>
        {/* url */}
        <div>
          <label htmlFor="url" className="label">
            <span className="label-text font-semibold">Website</span>
          </label>
          <input
            type="text"
            name="url"
            id="url"
            className="input input-bordered w-full"
            {...register("vendor.url")}
          />
        </div>
        {/* owned */}
        <div>
          <label htmlFor="owned" className="label">
            <span className="label-text font-semibold">Owned</span>
          </label>
          <select
            defaultValue={"Select"}
            className="select select-bordered w-full"
            required
            {...register("vendor.owned")}
          >
            <option disabled>Select</option>
            <option value="NO">No</option>
            <option value="YES">Yes</option>
          </select>
        </div>
        {/* status */}
        {mode === "update" && (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              defaultValue={"Select"}
              className="select select-bordered w-full"
              required
              {...register("vendor.status")}
            >
              <option disabled>Select</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>
        )}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}
